<template>
    <div class="bg-gray-900 min-h-screen py-24 sm:py-32" id="Pricing">
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="mx-auto text-4xl text-center">
          <!-- Added !important here -->
          <h2 class="text-base font-semibold leading-7 text-6xl text-indigo-400" style="font-size: 4rem !important;">Pricing</h2>
        </div>
        <p class="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-300">
            Discover our affordable detailing packages, tailored to rejuvenate your vehicle's interior. Experience meticulous attention to detail, ensuring a pristine and luxurious finish every time.
        </p>
        <div class="mt-16 flex justify-center">
        </div>
        <div class="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          <div v-for="tier in tiers" :key="tier.id" :class="[tier.mostPopular ? 'bg-white/5 ring-2 ring-indigo-500' : 'ring-1 ring-white/10', 'rounded-3xl p-8 xl:p-10']">
            <div class="flex items-center justify-between gap-x-4">
              <!-- Added !important here -->
              <h3 :id="tier.id" class="text-lg font-semibold leading-8 text-3xl text-indigo-300">{{ tier.name }}</h3>
              <p v-if="tier.mostPopular" class="rounded-full bg-indigo-500 px-2.5 py-1 text-xs font-semibold leading-5 text-white">Most popular</p>
            </div>
            <!-- Adjusted the text size style here -->
            <p class="mt-4 text-sm leading-6 pt-6 text-indigo-500 text-center" style="font-size: 4rem !important;">{{ tier.description }}</p>
            <p class="mt-6 flex items-baseline gap-x-1">
            </p>
            <ul role="list" class="mt-8 space-y-3 text-sm leading-6 text-gray-300 xl:mt-10">
                <!-- Added !important here -->
                <h1 class="text-lg" style="font-size: 1.5rem !important;">Cleaning of all interior surfaces </h1>
              <li v-for="feature in tier.features" :key="feature" class="flex gap-x-3">
                <CheckIcon class="h-6 w-5 flex-none text-white" aria-hidden="true" />
                {{ feature }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
</template>
  
  <script lang="ts">
import { defineComponent, ref } from 'vue'
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'
import { CheckIcon } from '@heroicons/vue/20/solid'

export default defineComponent({
  name: 'Prices',
  components: {
    RadioGroup,
    RadioGroupLabel,
    RadioGroupOption,
    CheckIcon
  },
  setup() {

    const tiers = ref([
      {
        name: 'Full Truck',
        description: '$170',
        features: ['Floors', 'Doors', 'Seats', 'Sleepers', 'Walls', 'Curtains', 'Cabinets', 'Dashboard', 'Windows'],
        mostPopular: false,
      },
      {
        name: 'Selective Truck',
        description: '$120',
        features: [
          'Floors',
          'Doors',
          'Cabinets',
          'Dashboard',
          'Windows',
        ],
        mostPopular: true,
      },
      {
        name: 'Car/SUV',
        id: 'tier-enterprise',
        description: '$100',
        features: ['Floors', 'Doors', 'Seats', 'Walls', 'Ceiling', 'Cabinets', 'Dashboard', 'Windows'],
        mostPopular: false,
      },
    ])


    // If you want to use these properties in the template section
    // of your Vue component, you need to return them:
    return {
      tiers,

    }
  }
})
</script>
