import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './main.css'
import { initializeApp } from 'firebase/app'

const firebaseConfig = {
    apiKey: "AIzaSyDRhKCPoojfZILh9KlyGZi0sgd3Q4NZvj4",
    authDomain: "nix-detailing.firebaseapp.com",
    projectId: "nix-detailing",
    storageBucket: "nix-detailing.appspot.com",
    messagingSenderId: "433726732403",
    appId: "1:433726732403:web:b28a8a69518f2a9cbdd206"
};

// Initialize Firebase without assignment
initializeApp(firebaseConfig);

createApp(App).use(store).use(router).mount('#app')
