<template>
    <div class="bg-gray-800 About min-h-screen" id="About">
          <!-- Content section -->
    <div class="mx-auto min-h-full max-w-7xl text-center px-6 lg:px-8">
        <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
          <div class="grid max-w-xl pt-20 grid-cols-1 gap-8 text-base leading-7 text-gray-300 lg:max-w-none lg:grid-cols-2">
            <div>
                <h1 class="text-2xl p-5 font-bold ">About Nixx Detailing LLC.</h1>
              <p>At Nixx Detailing Inc., our passion is bringing the life back into trucks. We understand that for many, a truck is not just a vehicle; it's an extension of who they are, a symbol of hard work, determination, and freedom on the open road.</p>
              <h1 class="text-2xl p-5 font-bold ">Our History</h1>
              <p class="mt-8">Founded in 2022, we started with a simple mission: to provide unmatched interior truck detailing services. Over the years, we've expanded, refined our techniques, and integrated the latest technology, but our core commitment to excellence has remained unchanged.</p>
            </div>
            <div>
                <h1 class="text-2xl p-5 font-bold ">Our Process</h1>
              <p>We begin with an in-depth assessment to understand the specific needs of each truck. Using eco-friendly, top-grade cleaning agents and state-of-the-art equipment, we meticulously clean, sanitize, and restore the interior to its former glory or even better. Our attention to detail means we don’t just clean; we enhance.</p>
              <h1 class="text-2xl p-5 font-bold ">Why Interior Truck Detailing?</h1>
              <p class="mt-8">A truck's exterior might face the wear and tear of the road, but its interior experiences its own challenges. From spilled drinks to dust accumulation and the daily grind, a truck's cabin can lose its charm quickly. That's where we come in. Our experts dive deep to rejuvenate every nook and cranny, ensuring a fresh, clean, and comfortable ride for our clients.</p>
            </div>
            <div>
                <h1 class="text-2xl p-5 font-bold ">Commitment to Quality</h1>
              <p>Quality isn't just a buzzword for us; it's our way of life. Every member of our team is trained extensively, not just in the techniques of detailing but in understanding the unique needs and concerns of truck drivers. We ensure that every truck we work on feels like a brand new vehicle once we're done.</p>
            </div>
            <div>
                <h1 class="text-2xl p-5 font-bold ">Join the Nixx Experience</h1>
              <p>At Nixx Detailing Inc., we don’t just offer a service; we offer an experience. An experience of rediscovering your truck's interior, pristine, clean, and invigorated. Whether you’re hitting the road for a long journey or just want a refreshing drive around town, we ensure your truck is ready for the task</p>
            </div>
          </div>
          <h1 class=" font-bold text-slate-400 text-3xl mt-36 text-center">Come experience the Nixx difference. Your truck deserves it.</h1>
          <dl class="mt-16 grid grid-cols-1 gap-x-8 gap-y-12 sm:mt-20 sm:grid-cols-2 sm:gap-y-16 lg:mt-28 lg:grid-cols-4">
          </dl>
        </div>
      </div>
    </div>
</template>

<script lang="ts">
import { ref } from 'vue'

export default {
    name: 'About',
    setup() {

        return {
        }
    }
}
</script>


<style scoped>

</style>