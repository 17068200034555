import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import MainPage from '../views/MainPage.vue'
import Header from '../components/Header.vue'
import Hero from '../components/HeroSection.vue'
import About from '../views/AboutUs.vue'
import Prices from '../components/Prices.vue'
import Contact from '../components/ContactUs.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'MainPage',
    component: MainPage
  },
  {
    path: '/Header',
    name: 'Header',
    component: Header
  },
  {
    path: '/Hero',
    name: 'Hero',
    component: Hero
  },
  {
    path: '/About',
    name: 'About',
    component: About
  },
  {
    path: '/Prices',
    name: 'Prices',
    component: Prices
  },
  {
    path: '/Contact',
    name: 'Contact',
    component: Contact
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
