<template>
    <div class="bg-gray-900 min-h-screen min-w-full pt-16 overflow-hidden">
              <!-- Hero section -->
      <div class="relative isolate overflow-hidden">
        <svg class="absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]" aria-hidden="true">
          <defs>
            <pattern id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc" width="200" height="200" x="50%" y="-1" patternUnits="userSpaceOnUse">
              <path d="M.5 200V.5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y="-1" class="overflow-visible fill-gray-800/20">
            <path d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z" stroke-width="0" />
          </svg>
          <rect width="100%" height="100%" stroke-width="0" fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)" />
        </svg>
        <div class="absolute left-[calc(50%-4rem)] top-10 -z-10 transform-gpu blur-3xl sm:left-[calc(50%-18rem)] lg:left-48 lg:top-[calc(50%-30rem)] xl:left-[calc(50%-24rem)]" aria-hidden="true">
          <div class="aspect-[1108/632] w-[69.25rem] bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-20" style="clip-path: polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)" />
        </div>
        <div class="mx-auto max-w-7xl px-6 pt-10 sm:pb-40 lg:flex lg:px-8 lg:pt-40">
            <div class="mx-auto max-w-2xl flex-shrink-0 lg:mx-0 lg:max-w-xl lg:pt-8">
            <img class="h-[150px]" src="../assets/Nikola-Logo1-01.svg" alt="Your Company" />
            <h1 class="mt-10 text-4xl font-bold tracking-tight text-white sm:text-6xl">Nix Detailing LLC.</h1>
            <p class="mt-6 text-lg leading-8 text-gray-300">
                Experience the difference with our top-tier interior detailing. Restore the original shine of your vehicles interior, ensuring it feels brand new every time you hit the road. Quality, precision, and dedication – that's what we deliver in every detail.
            </p>
            <div class="mt-10 flex items-center gap-x-6">
                <a href="#Contact" class="rounded-lg bg-indigo-500 px-5 py-3 text-lg font-semibold text-white shadow-lg hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400">Get Started</a>
            </div>
          </div>
          <div class="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-32 relative before:absolute before:inset-x-0 before:bottom-0 before:w-full before:h-1/2 before:bg-gradient-to-t before:from-gray-900 before:to-transparent before:z-10">
            <div class="max-w-3xl mx-auto sm:max-w-5xl lg:max-w-none">
                <img src="../assets/sediste.png" alt="App screenshot" class="w-full h-[400px] sm:h-[650px] relative z-0 mx-auto" />
            </div>
            </div>
        </div>
      </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Hero',
    setup () {
        

        return {}
    }
})
</script>

<style scoped>

</style>