<template>
    <div class="main-container max-h-screen flex flex-col bg-gray-900">  
        <div class="Header">
            <Header/>
        </div>
        <div class="Hero">
            <Hero/>
        </div>
        <div class="About">
            <About/>
        </div>
        <div class="Prices">
            <Prices/>
        </div>
        <div class="Contact">
            <Contact/>
        </div>
    </div>
</template>    

<script lang="ts">
import Header from '@/components/Header.vue'
import Hero from '@/components/HeroSection.vue'
import About from '@/views/AboutUs.vue'
import Prices from '../components/Prices.vue'
import Contact from '../components/ContactUs.vue'

export default {
    name: 'MainPage',
    components: {
        Header,
        Hero,
        About,
        Prices,
        Contact,
    },    
    setup () {
        return {}
    }
}
</script>

<style scoped>

</style>
